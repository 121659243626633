.benefits {
  .section-title {
    color: $color-white;
    text-align: left;
  }

  &:before {
    background: linear-gradient(-45deg,#ee7752,#e73c7e,#39c8df,#ddeebb);
    background-size: 400% 400% !important;
    animation: animate_scroll 15s ease infinite;
    transition: all 1s ease-out !important;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.benefits-list {
  display: block;
  position: relative;
  list-style-type: none;
  margin-bottom: 40px;
  width: 100%;

  li {
    color: $color-white;
    display: block;
    font-size: 16px;
    padding-left: 25px;
    position: relative;

    &:before {
      color: #232323;
      font-family: 'icomoon' !important;
      content: $ico-check-square;
      display: block;
      position: absolute;
      font-size: 15px;
      top: 1px;
      left: 0;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.benefits-con {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.benefits-detail,
.benefits-img {
  display: block;
  position: relative;

}

.benefits-detail {
  width: 100%;
  z-index: 2;
  
  @include breakpoint(850px) {
    width: 50%;
  }
}

.benefits-img {
  padding: 0 15px;
  width: 80%;

  @include breakpoint(849px, max-width) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }

  @include breakpoint(850px) {
    width: 50%;
  }

  img { width: 100%; }
}
