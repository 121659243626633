@charset "UTF-8";
/*! sanitize.css | CC0 Public Domain | github.com/jonathantneal/sanitize.css */
/*
 * Normalization
 */
:root {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  text-size-adjust: 100%;
}

audio:not([controls]) {
  display: none;
}

details {
  display: block;
}

input[type=number] {
  width: auto;
}
input[type=search] {
  -webkit-appearance: textfield;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

main {
  display: block;
}

summary {
  display: block;
}

pre {
  overflow: auto;
}

progress {
  display: inline-block;
}

small {
  font-size: 75%;
}

template {
  display: none;
}

textarea {
  overflow: auto;
}

[hidden] {
  display: none;
}

[unselectable] {
  user-select: none;
}

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

/*
 * Opinionated defaults
 */
*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  box-sizing: border-box;
  cursor: default;
  font: 16px/1.5 sans-serif;
  text-rendering: optimizeLegibility;
}

html {
  background-color: #FFFFFF;
}

a {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  background-color: transparent;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

button,
[type=button],
[type=date],
[type=datetime],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=reset],
[type=search],
[type=submit],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
  min-height: 1.5em;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}

nav ol,
nav ul {
  list-style: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
select::-ms-expand {
  display: none;
}
select::-ms-value {
  color: currentColor;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: vertical;
}

::selection {
  background-color: #B3D4FC;
  text-shadow: none;
}

@media screen {
  [hidden~=screen] {
    display: inherit;
  }
  [hidden~=screen]:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}
@font-face {
  font-family: "icomoon";
  src: url("../lib/fonts/icomoon/icomoon.eot?ugcm40");
  src: url("../lib/fonts/icomoon/icomoon.eot?ugcm40#iefix") format("embedded-opentype"), url("../lib/fonts/icomoon/icomoon.ttf?ugcm40") format("truetype"), url("../lib/fonts/icomoon/icomoon.woff?ugcm40") format("woff"), url("../lib/fonts/icomoon/icomoon.svg?ugcm40#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/*====================================*\
  >> Functions
/*====================================*/
body {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: auto;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #333;
  background-color: #fffß;
  font-weight: 400;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.flex {
  box-sizing: border-box;
  flex: 1;
  display: flex;
}
.flex:before, .flex:after {
  content: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-fill {
  width: 100%;
  height: 100%;
}

.flex-horizontal {
  box-orient: horizontal;
  box-direction: normal;
  flex-direction: row;
}

.flex-vertical {
  box-orient: vertical;
  box-direction: normal;
  flex-direction: column;
}

.flex-space-between {
  justify-content: space-between;
  flex-pack: justify;
  box-pack: justify;
}

.flex-space-around {
  justify-content: space-around;
  flex-pack: justify;
  box-pack: justify;
}

.flex-start {
  justify-content: flex-start;
  flex-pack: start;
  box-pack: start;
}

.flex-end {
  justify-content: flex-end;
  flex-pack: end;
  box-pack: end;
}

.flex-center {
  justify-content: center;
  flex-pack: center;
  box-pack: center;
}

.flex-align-start {
  align-items: flex-start;
  flex-align: start;
  box-align: start;
}

.flex-align-end {
  align-items: flex-end;
  flex-align: end;
  box-align: end;
}

.flex-align-center {
  align-items: center;
  flex-align: center;
  box-align: center;
}

.flex-align-baseline {
  align-items: baseline;
  flex-align: baseline;
  box-align: baseline;
}

.flex-align-stretch {
  align-items: stretch;
  flex-align: stretch;
  box-align: stretch;
}

.flex-inline {
  display: inline-flex;
}

.scroll::-webkit-scrollbar, .modal-body-inner::-webkit-scrollbar, .modal-body::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  padding: 0 2px;
  position: relative;
  z-index: 10;
}
.scroll::-webkit-scrollbar-track, .modal-body-inner::-webkit-scrollbar-track, .modal-body::-webkit-scrollbar-track {
  background-color: #39c8df;
}
.scroll::-webkit-scrollbar-thumb, .modal-body-inner::-webkit-scrollbar-thumb, .modal-body::-webkit-scrollbar-thumb {
  background-color: #232323;
}

.l-wrapper {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 90px;
}

.l-header {
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #e6e6e6;
  display: block;
  height: 90px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
@media only screen and (min-width: 48em) {
  .l-header {
    padding: 0 40px;
  }
}

.l-main {
  display: block;
  position: relative;
  width: 100%;
}

.l-footer {
  border-top: 1px solid #e6e6e6;
  display: block;
  padding: 40px 20px;
  position: relative;
  width: 100%;
  background-color: #232323;
}

.l-section {
  display: block;
  padding: 80px 35px;
  position: relative;
  width: 100%;
}

.l-container {
  display: block;
  position: relative;
  max-width: 1130px;
  margin: 0 auto;
  width: 100%;
}

.header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 100%;
  max-width: 1130px;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.header-menu {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.logo {
  align-items: center;
  display: flex;
  position: relative;
  max-width: 209px;
  height: 100%;
}
.logo a {
  display: block;
  position: relative;
}
.logo img {
  width: 100%;
}

.header-btn {
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 100%;
}
.header-btn > div {
  margin: 0 8px;
  cursor: pointer;
}

.header-auth {
  margin-left: 20px;
  display: none;
  height: 100%;
}
@media only screen and (min-width: 64.0625em) {
  .header-auth {
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
}

.navigation-wrap,
.navigation {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 64.0625em) {
  .navigation-wrap {
    flex: 1 1 auto;
  }
}
@media only screen and (max-width: 64em) {
  .navigation-wrap {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;
  }
}
@media only screen and (max-width: 64em) {
  .navigation-wrap.is-open {
    opacity: 1;
  }
}

@media only screen and (max-width: 64em) {
  .navigation {
    background: #fff;
    max-width: 300px;
    height: 100%;
    width: 100%;
    transform: translateX(-300px);
    transition: transform 0.2s;
  }
}
@media only screen and (max-width: 64em) {
  .is-open .navigation {
    transform: translateX(0);
  }
}

.navigation-inner {
  display: block;
  margin-bottom: 0;
}
@media only screen and (min-width: 64.0625em) {
  .navigation-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.navigation-link, .navigation-sub-link {
  color: #232323;
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: capitalize;
  line-height: 1;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 14px;
  transition: color 0.2s ease-in-out;
}

.navigation-item {
  display: block;
  padding: 0 12px;
  position: relative;
  height: 40px;
}
@media only screen and (min-width: 64.0625em) {
  .navigation-item {
    height: 100%;
  }
}
@media only screen and (min-width: 64.0625em) {
  .navigation-item:hover .navigation-link {
    color: #39c8df;
  }
  .navigation-item:hover .navigation-sub-item {
    opacity: 1;
    margin-top: 0;
  }
}
.navigation-item.is-active .navigation-link {
  font-weight: bold;
  color: #39c8df;
}

.navigation-sub {
  background: #fff;
  display: block;
  position: absolute;
  top: 89px;
  left: 0;
  min-width: 200px;
  overflow: hidden;
  width: 100%;
  z-index: 2;
}

.navigation-sub-item {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  display: block;
  position: relative;
  width: 100%;
  margin-top: -36px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, margin 0.4s ease-in-out;
}
.navigation-sub-item .navigation-sub-link {
  padding: 0 20px;
  height: 34px;
}
.navigation-sub-item:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.navigation-auth {
  display: block;
  position: relative;
  padding: 0 10px;
  margin-top: 20px;
  width: 100%;
}
@media only screen and (min-width: 64.0625em) {
  .navigation-auth {
    display: none;
  }
}
.navigation-auth > div {
  display: block;
  margin-right: 0;
  margin-bottom: 10px;
}

.contents-wrap {
  display: block;
  position: relative;
  width: 100%;
}

.content-inner {
  display: block;
  position: relative;
  max-width: 1215px;
  margin: 0 auto;
  width: 100%;
}

.footer {
  color: #232323;
}
.footer .logo {
  margin: 0 auto;
  max-width: 170px;
}

.footer-col-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  position: relative;
  width: 100%;
}

.footer-col {
  display: block;
  position: relative;
  width: 25%;
}
.footer-col:not(:first-child) {
  padding-left: 3%;
}
.footer-col h3 {
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  position: relative;
  text-transform: capitalize;
  color: #fff;
}

.footer-nav {
  display: block;
  list-style: none;
  position: relative;
  width: 100%;
}

.footer-nav-item {
  display: block;
  position: relative;
}
.footer-nav-item:not(:last-child) {
  margin-bottom: 5px;
}

.footer-nav-link {
  color: #39c8df;
  position: relative;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
}

.footer-contact {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 400;
}
.footer-contact i {
  color: #39c8df;
  display: block;
  margin-right: 10px;
}
.footer-contact-intro {
  color: #999;
  display: block;
  padding-left: 0;
}

.copyright-container {
  margin: 40px 0px;
  border-top: 1px solid #e6e6e6;
}

.copyright {
  color: #ccc;
  /* text-align: center; */
  line-height: 60px;
  float: left;
}

.copyright-menu {
  float: right;
  line-height: 60px;
  letter-spacing: 0.3px;
}

.copyright-menu-item {
  display: inline-block;
  padding-left: 10px;
}

/* Hoover */
.copyright-nav-link {
  color: #39c8df;
  position: relative;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
}

.copyright-nav-link a:hover,
.footer-nav-link a:hover {
  color: #00a0e3 !important;
}

.footer-col a:hover,
.footer-col p a:hover,
.copyright-menu-item a:hover {
  color: #00a0e3 !important;
}

.footer-col a:visited,
.footer-col p a:link,
.footer-col p a:visited {
  color: #39c8df;
}

[class^=ico-], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  display: block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^=ico-]:before, [class*=" ico-"]:before {
  display: block;
  position: relative;
}

.ico-lock {
  color: #39c8df;
  font-size: 36px;
}
.ico-lock:before {
  content: "\e909";
}

.ico-trophy {
  color: #39c8df;
  font-size: 36px;
}
.ico-trophy:before {
  content: "\e90b";
}

.ico-certificate {
  color: #39c8df;
  font-size: 36px;
}
.ico-certificate:before {
  content: "\e908";
}

.ico-shield {
  color: #39c8df;
  font-size: 36px;
}
.ico-shield:before {
  content: "\e907";
}

.ico-laptop {
  color: #39c8df;
  font-size: 36px;
}
.ico-laptop:before {
  content: "\e906";
}

.ico-paypal {
  color: #39c8df;
  font-size: 36px;
}
.ico-paypal:before {
  content: "\e90a";
}

.ico-commenting:before {
  content: "\e902";
}

.ico-user-o:before {
  content: "\e900";
}

.ico-user-circle:before {
  content: "\e901";
}

.ico-group-user:before {
  content: "\e905";
}

.ico-user-group:before {
  content: "\e972";
}

.ico-list:before {
  content: "\e904";
}

.ico-comments:before {
  content: "\e903";
}

.ico-check-square:before {
  content: "\e90c";
}

.ico-search:before {
  content: "\e90d";
}

.ico-phone:before {
  content: "\e90e";
}

.ico-envelop:before {
  content: "\e90f";
}

.ico-circle:before {
  content: "\ea57";
}

.ico-close:before {
  content: "\e910";
}

.ico-linkedin:before {
  content: "\f0e1"; /*url('https://api.iconify.design/icomoon-free:linkedin2.svg?height=16');
vertical-align: -0.125em;
color:#fff;*/
}

.ico-google:before {
  content: "\e912";
}

.ico-fb:before {
  content: "\ea90";
}

.ico-check:before {
  content: "\e915";
}

.ico-lost-pass:before {
  content: "\e914";
}

.ico-edit:before {
  content: "\e913";
}

.ico-logout:before {
  content: "\e91b";
}

.ico-cart {
  border: 2px solid #232323;
  display: block;
  width: 30px;
  position: absolute;
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
  height: 19px;
}
.ico-cart:before {
  display: block;
  content: "";
  position: absolute;
  top: -10px;
  right: 50%;
  width: 18px;
  height: 10px;
  margin-right: -9px;
  border: 2px solid #232323;
  border-bottom: 0;
  border-top-left-radius: 99px;
  border-top-right-radius: 99px;
  transition: all 0.2s ease-in-out;
}

.btn {
  display: block;
  position: relative;
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
}

.btn-default {
  background-color: #39c8df;
  border-radius: 3px;
  display: block;
  width: 100%;
  overflow: hidden;
}
.btn-default .btn-link {
  color: #fff;
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  padding: 14px 18px;
}

.btn-gradient-link {
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.btn-gradient-link .btn-link {
  color: #fff;
  line-height: 1;
  text-transform: capitalize;
  background-color: #00a0e3;
  background: linear-gradient(to right, #00a0e3 0%, #39c8df 50%, #00a0e3 100%);
  background-size: 200% 100%;
  transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 64.0625em) {
  .btn-gradient-link .btn-link:hover {
    background-color: #39c8df;
    background-position: 100% 0;
  }
}

.btn-gradient {
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  color: #fff;
  line-height: 1;
  text-transform: capitalize;
  background-color: #00a0e3;
  background: linear-gradient(to right, #00a0e3 0%, #39c8df 50%, #00a0e3 100%);
  background-size: 200% 100%;
  transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 64.0625em) {
  .btn-gradient:hover {
    background-color: #39c8df;
    background-position: 100% 0;
  }
}

.btn-auth {
  display: block;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}
@media only screen and (min-width: 64.0625em) {
  .btn-auth {
    margin-bottom: 0;
  }
}
.btn-auth span {
  pointer-events: none;
}
.btn-auth .btn-link {
  border: 2px solid #39c8df;
  color: #39c8df;
  padding: 6px 25px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  align-items: center;
}
@media only screen and (min-width: 64.0625em) {
  .btn-auth .btn-link:hover {
    border: 2px solid #232323;
    color: #232323;
  }
}
@media only screen and (min-width: 64.0625em) {
  .btn-auth:not(:last-child) {
    margin-right: 12px;
  }
}

.btn-benefits {
  border-radius: 50px;
  border: 2px solid #f7f7f7;
  color: #f7f7f7;
  display: block;
  height: 58px;
  position: relative;
  max-width: 373px;
  margin: 0 auto;
}
.btn-benefits .btn-link {
  color: #f7f7f7;
  padding: 0 25px;
  text-align: center;
  font-size: 18px;
}
.btn-benefits:not(:last-child) {
  margin-right: 20px;
}

.btn-mv {
  max-width: 268px;
  height: 55px;
}
.btn-mv .btn-link {
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  padding: 14px 18px;
}

.btn-profile, .btn-search {
  display: block;
  position: relative;
  width: 15px;
  height: 15px;
  text-align: center;
}
.btn-profile i, .btn-search i {
  transition: color 0.2s ease-in-out;
  color: #232323;
}
@media only screen and (min-width: 64.0625em) {
  .btn-profile:hover i, .btn-search:hover i {
    color: #39c8df;
  }
}

.btn-profile {
  cursor: pointer;
}
.btn-profile i {
  pointer-events: none;
}

.btn-logout {
  display: block;
  position: relative;
}
.btn-logout i {
  color: #e23f3f;
  font-size: 25px;
  pointer-events: none;
  transition: color 0.2s ease-in-out;
}
@media only screen and (min-width: 64.0625em) {
  .btn-logout:hover i {
    color: #bf0606;
  }
}

.btn-cart {
  display: block;
  position: relative;
  text-align: center;
  font-size: 13px;
  height: 19px;
  width: 30px;
}
.btn-cart i {
  bottom: 0;
  left: 0;
}

.btn-register {
  cursor: pointer;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  padding: 10px 14px;
  width: auto;
}

.btn-login {
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  height: 45px;
  padding: 10px 14px;
  margin-bottom: 10px;
}
.btn-login i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.btn-login-fb {
  background: #3a559f;
}
.btn-login-fb .btn-link {
  color: #fff;
}
.btn-login-fb i {
  font-size: 18px;
}

.btn-login-linkedin {
  background: #0073b1;
}
.btn-login-linkedin .btn-link {
  color: #fff;
}
.btn-login-linkedin i {
  font-size: 23px;
}

.btn-login-google {
  background: #d94738;
}
.btn-login-google .btn-link {
  color: #fff;
}
.btn-login-google i {
  font-size: 23px;
}

.btn-close {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}
.btn-close i {
  pointer-events: none;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  border-right: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}

.table-thead {
  display: table-header-group;
}

.table-body {
  display: table-row-group;
}

.table-tr {
  display: table-row;
}

.table-td {
  display: table-cell;
  vertical-align: middle;
  padding: 8px;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  color: #232323;
}
.table-td.description {
  width: 65%;
  font-weight: 500;
}

.form-wrap {
  color: #333;
  display: block;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.form-inner {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
}

.form-con {
  display: block;
  position: relative;
  width: 48%;
}

.form-input {
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.form-title {
  color: #232323;
  display: block;
  text-transform: none;
  line-height: 32px;
  letter-spacing: -1px;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  margin-bottom: 15px;
  position: relative;
}
.form-title strong {
  font-weight: 500;
}
.form-title .-number {
  display: inline-block;
  margin-right: 25px;
}

.form-lbl {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

.form-input-box {
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  display: block;
  height: 34px;
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  color: #777;
  font-size: 14px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.24s ease-in-out;
  -moz-transition: opacity 0.24s ease-in-out;
  transition: opacity 0.24s ease-in-out;
}
.form-input-box input, .form-input-box select {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0 10px;
  outline: 0;
}

.form-note {
  display: block;
  margin-top: 5px;
  position: relative;
  width: 100%;
}

.form-error {
  color: red;
  display: block;
  margin-top: 5px;
  position: relative;
  width: 100%;
}

.form-check input {
  display: block;
  position: absolute;
  opacity: 0;
}
.form-check label {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 13px;
  font-weight: 400;
}
.form-check label::before {
  background: #e6e6e6;
  border: 1px solid #c1c1c1;
  border-radius: 2px;
  content: "";
  display: block;
  height: 16px;
  margin-right: 10px;
  width: 15px;
  position: relative;
}
.form-check label::after {
  color: #232323;
  border-radius: 2px;
  content: "\e915";
  font-family: "icomoon" !important;
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 2px;
  font-size: 13px;
  line-height: 1;
  transform: scale(0);
  opacity: 0;
  top: 3px;
  transform-origin: bottom left;
  transition: all 0.3s cubic-bezier(0.3, 0.7, 0.48, 0.96);
}
.form-check input:checked + label::after {
  transform: scale(1);
  opacity: 1;
}

.form-extra {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.form-extra-link {
  color: #232323;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}
.form-extra-link i {
  font-size: 14px;
  margin-right: 5px;
}
.form-extra-link span {
  border-bottom: 1px solid transparent;
}
@media only screen and (min-width: 64.0625em) {
  .form-extra-link:hover span {
    border-bottom-color: #232323;
  }
}

.form-extra-link-register {
  margin-left: auto;
  margin-right: 10px;
}

.form-title {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 1;
}

.form-login {
  margin: 100px auto;
  max-width: 900px;
}
.form-login .form-con {
  width: 42%;
}
.form-login .form-inner:before {
  background: #e6e6e6;
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-login .form-inner:after {
  background: #fff;
  content: "OR";
  display: block;
  position: absolute;
  text-align: center;
  padding: 10px 0;
  width: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-login .form-input-box {
  height: 45px;
  letter-spacing: 0px;
}
.form-login .form-input-box input, .form-login .form-input-box select {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0 10px;
  outline: 0;
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 0;
  text-align: center;
  z-index: 999;
}
@media only screen and (min-width: 62em) {
  .modal .modal .modal-inner {
    max-width: 1060px;
    width: 90%;
  }
}

.modal-inner {
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-color: 1px solid rfba(#fff, 0.125);
  padding: 0;
  pointer-events: initial;
  position: relative;
  text-align: left;
  width: 94%;
  z-index: 2;
}
@media only screen and (min-width: 48em) {
  .modal-inner {
    width: 60%;
    max-width: 800px;
  }
}

.modal__header,
.modal__body,
.modal__footer {
  background-color: transparent;
}

.modal-header {
  background-color: #39c8df;
  color: #232323;
  height: 50px;
  z-index: -1;
}

.modal-header-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-header-inner:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.modal-header-inner > *:not(:last-child) {
  margin-right: 10px;
}
.modal-inner--button .modal-header-inner {
  justify-content: flex-end;
}
.-button .modal-header-inner {
  text-align: right !important;
}

.modal-title {
  color: #39c8df;
  font-size: 1.875em;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin: 0;
  text-align: left;
  text-transform: uppercase;
  padding-left: 10px;
}

.modal-buttons {
  z-index: 10;
}

.modal-body {
  overflow-y: auto;
  font-size: 15px;
  max-height: calc(100% - 50px);
}
.dp--open .modal-body {
  overflow-y: auto;
}

.modal-body-inner {
  width: 100%;
  height: 100%;
  padding: 15px;
  max-height: inherit;
}
.modal-body-inner .tab-contents-inner {
  position: relative;
  min-height: 157px;
}

.modal__footer .-left {
  float: left;
}
.modal__footer .-right {
  float: right;
}

.modal__footer-inner {
  padding: 10px 15px;
}

@keyframes animate_scroll {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.hamburger-wrap {
  display: block;
  position: relative;
  width: 35px;
  height: 24px;
  margin-left: 10px;
  z-index: 100;
}
@media only screen and (min-width: 64.0625em) {
  .hamburger-wrap {
    display: none;
  }
}

.hamburger {
  border-radius: 10px;
  display: block;
  position: relative;
  transform: translateY(9px);
  background: #232323;
  transition: all 0ms 300ms;
  width: 100%;
  height: 4px;
  pointer-events: none;
}
.hamburger:before, .hamburger:after {
  border-radius: 10px;
  content: "";
  position: absolute;
  left: 0;
  background: #232323;
  width: 100%;
  height: 4px;
}
.hamburger:before {
  bottom: 9px;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger:after {
  top: 9px;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.is-open .hamburger {
  background: transparent;
}
.is-open .hamburger:before {
  background: #fff;
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.is-open .hamburger:after {
  background: #fff;
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.mv {
  display: block;
  height: 587px;
  position: relative;
  width: 100%;
}
.mv:before {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.82) 41%, rgba(54, 149, 156, 0.47) 67%, rgba(0, 220, 255, 0.36) 75%, rgba(195, 195, 195, 0.6) 100%);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.mv-animate {
  background-image: url(https://aardvark.ghostpool.com/original/wp-content/uploads/sites/6/2017/11/header-bg.jpg?id=219) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: block;
  width: 100%;
  height: 100%;
  animation: 25s animate_scroll infinite linear;
  transition: opacity 0.5s ease;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.mv-inner {
  display: block;
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 80px 40px;
  height: 100%;
  width: 100%;
  z-index: 2;
}
@media only screen and (min-width: 48em) {
  .mv-inner {
    background-image: url("../../../assets/images/membership-circles.png");
    background-repeat: no-repeat;
    background-size: 59%;
    background-position: right bottom;
  }
}

.mv-title {
  display: block;
  position: relative;
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 40px;
  margin-bottom: 30px;
}
.mv-title span {
  color: #39c8df;
  display: block;
  font-weight: 300;
  letter-spacing: -3px;
  line-height: 50px;
  font-size: 50px;
  padding-left: 20px;
}

.mv-list {
  display: block;
  list-style-type: none;
  position: relative;
  margin-bottom: 42px;
  width: 100%;
}

.mv-item {
  display: block;
  padding-left: 25px;
  position: relative;
  margin-bottom: 12px;
}
.mv-item p {
  color: #fff;
  display: block;
  font-size: 20px;
}
.mv-item i {
  color: #39c8df;
  font-size: 18px;
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
}

.section-con {
  display: block;
  padding: 80px 35px;
  position: relative;
  width: 100%;
}

.section-inner {
  display: block;
  position: relative;
  max-width: 1215px;
  margin: 0 auto;
  width: 100%;
}

.section-title {
  color: #232323;
  display: block;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  font-weight: 700;
  position: relative;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.section-title-sub {
  display: block;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-weight: 400;
  position: relative;
  margin-bottom: 40px;
}

.membership-list {
  color: #232323;
  display: flex;
  align-items: baseline;
  position: relative;
  flex-wrap: wrap;
  left: -15px;
  width: calc(100% + 30px);
}

.membership-item {
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  align-self: stretch;
  padding: 0 15px;
  width: 100%;
}
@media only screen and (min-width: 48em) {
  .membership-item {
    width: 50%;
  }
}
@media only screen and (min-width: 64.0625em) {
  .membership-item {
    width: 33.3333333333%;
  }
}
.membership-item p {
  display: block;
  position: relative;
  text-align: center;
}
.membership-item p:not(:last-child) {
  margin-bottom: 5px;
}

.membership-type {
  background: #232323;
  display: block;
  position: relative;
  text-align: center;
  padding: 20px;
}
.membership-type span {
  color: #fff;
  display: block;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.membership-pricing {
  display: block;
  padding: 30px 0;
  position: relative;
  width: 100%;
}
.membership-pricing:before {
  background: #e6e6e6;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 75%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.membership-pricing-price {
  display: block;
  position: relative;
  text-align: center;
}
.membership-pricing-price span {
  display: inline-block;
  position: relative;
  font-size: 48px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -4px;
  text-align: center;
}

.membership-pricing-amount {
  font-size: 48px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -4px;
}

.membership-pricing-note {
  font-size: 16px;
  opacity: 0.5;
}

.membership-content {
  display: block;
  padding: 30px;
  position: relative;
  width: 100%;
}
.membership-content:before {
  background: #e6e6e6;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 75%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.membership-content-list {
  display: block;
  list-style-type: none;
  position: relative;
  width: 100%;
}
.membership-content-list li {
  display: block;
  font-size: 16px;
  text-align: center;
  width: 100%;
}
.membership-content-list li:not(:last-child) {
  margin-bottom: 20px;
}

.membership-btn {
  display: block;
  padding: 30px;
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.paid-membership-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.paid-membership-item {
  display: block;
  padding: 0 15px;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 48em) {
  .paid-membership-item {
    width: 50%;
  }
}
@media only screen and (min-width: 64.0625em) {
  .paid-membership-item {
    width: 33.3333333333%;
  }
}
.paid-membership-item:nth-child(-n+3) {
  margin-bottom: 35px;
}
.paid-membership-item i {
  position: absolute;
  left: 0;
  top: 0;
}
.paid-membership-item h3 {
  color: #232323;
  display: block;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4em;
  position: relative;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.paid-membership-item p {
  color: #999;
  font-size: 16px;
  line-height: 27px;
}

.paid-membership-detail {
  padding-left: 60px;
  font-size: 16px;
  line-height: 1.6em;
}
.paid-membership-detail ul li {
  margin-left: 15px;
}

.benefits .section-title {
  color: #fff;
  text-align: left;
}
.benefits:before {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #39c8df, #ddeebb);
  background-size: 400% 400% !important;
  animation: animate_scroll 15s ease infinite;
  transition: all 1s ease-out !important;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.benefits-list {
  display: block;
  position: relative;
  list-style-type: none;
  margin-bottom: 40px;
  width: 100%;
}
.benefits-list li {
  color: #fff;
  display: block;
  font-size: 16px;
  padding-left: 25px;
  position: relative;
}
.benefits-list li:before {
  color: #232323;
  font-family: "icomoon" !important;
  content: "\e90c";
  display: block;
  position: absolute;
  font-size: 15px;
  top: 1px;
  left: 0;
}
.benefits-list li:not(:last-child) {
  margin-bottom: 12px;
}

.benefits-con {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.benefits-detail,
.benefits-img {
  display: block;
  position: relative;
}

.benefits-detail {
  width: 100%;
  z-index: 2;
}
@media only screen and (min-width: 53.125em) {
  .benefits-detail {
    width: 50%;
  }
}

.benefits-img {
  padding: 0 15px;
  width: 80%;
}
@media only screen and (max-width: 53.0625em) {
  .benefits-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }
}
@media only screen and (min-width: 53.125em) {
  .benefits-img {
    width: 50%;
  }
}
.benefits-img img {
  width: 100%;
}

.statistics {
  background-color: #232323;
  display: block;
  padding: 20px 35px;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 53.125em) {
  .statistics {
    padding: 40px 35px;
  }
}

.statistics-list {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
}

.statistics-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 20px 0;
  width: 33.3333333333%;
}
@media only screen and (min-width: 53.125em) {
  .statistics-item {
    width: 16.6666666667%;
    margin: 0;
  }
}
.statistics-item i {
  color: #39c8df;
  font-size: 48px;
  margin-bottom: 15px;
}
.statistics-item h3 {
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -1px;
  position: relative;
  color: #999;
  text-transform: capitalize;
}
.statistics-item span {
  color: #fff;
  display: block;
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -1px;
}

.subpage {
  display: block;
  padding: 0 20px 40px;
  position: relative;
  width: 100%;
}
.subpage > p {
  color: #333;
  display: block;
  margin-bottom: 12px;
  line-height: 2;
  letter-spacing: 0.002em;
}

.subpage-title {
  color: #232323;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  padding: 30px 20px;
  left: -20px;
  width: calc(100% + 40px);
  margin-bottom: 40px;
  position: relative;
}
.subpage-title h1 {
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -1px;
  font-weight: 400;
}

.subpage-title-inner {
  display: block;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.subpage-inner {
  display: flex;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.subpage-content-title {
  color: #232323;
  display: block;
  text-transform: none;
  line-height: 32px;
  letter-spacing: -1px;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  margin-bottom: 10px;
  position: relative;
}
.subpage-content-title strong {
  font-weight: 500;
}
.subpage-content-title .-number {
  display: inline-block;
  margin-right: 25px;
}

.subpage-box {
  display: block;
  position: relative;
  margin-bottom: 30px;
}
.subpage-box > p {
  color: #333;
  display: block;
  margin-bottom: 12px;
  line-height: 2;
  letter-spacing: 0.002em;
}

.subpage-content {
  display: block;
  width: 72%;
  position: relative;
}

.subpage-text {
  color: #333;
  display: block;
  margin-bottom: 12px;
  line-height: 2;
  letter-spacing: 0.002em;
}

.subpage-sidebar {
  display: block;
  width: 25%;
  position: relative;
  margin-left: 3%;
}

.default-link {
  color: #39c8df;
  transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 64.0625em) {
  .default-link:hover {
    color: #00a0e3;
  }
}

.ordered-list {
  color: #333;
  display: block;
  position: relative;
  list-style-type: decimal;
  width: 100%;
  list-style-position: inside;
  padding-left: 10px;
  margin-bottom: 12px;
}
.ordered-list li {
  line-height: 2;
}

.subpage-body h1, .subpage-body h2, .subpage-body h3, .subpage-body h4, .subpage-body h5 {
  display: block;
  position: relative;
}
.subpage-body h2 {
  color: #232323;
  display: block;
  text-transform: none;
  line-height: 32px;
  letter-spacing: -1px;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  margin-bottom: 10px;
  position: relative;
}
.subpage-body h2 strong {
  font-weight: 500;
}
.subpage-body h2 .-number {
  display: inline-block;
  margin-right: 25px;
}
.subpage-body p {
  color: #232332;
  display: block;
  margin-bottom: 20px;
  line-height: 1.8;
  letter-spacing: 0.002em;
}
.subpage-body a {
  color: #39c8df;
  transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 64.0625em) {
  .subpage-body a:hover {
    color: #00a0e3;
  }
}
.subpage-body ol {
  color: #333;
  display: block;
  position: relative;
  list-style-type: decimal;
  width: 100%;
  list-style-position: inside;
  padding-left: 10px;
  margin-bottom: 12px;
}
.subpage-body ol li {
  line-height: 2;
}

.licence .subpage-content-title {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.licence .subpage-content-title:before {
  background-color: #e6e6e6;
  display: block;
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.licence .subpage-content-title:first-child {
  margin-bottom: 63px;
}

.academia-box {
  display: block;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}
.academia-box strong {
  font-weight: 500;
}

.academia-list {
  display: block;
  list-style-type: none;
  width: 100%;
}
.academia-list > li {
  color: #333;
  display: block;
  line-height: 2;
  margin-bottom: 12px;
  width: 100%;
}

.academia-sub-list {
  display: block;
  position: relative;
  margin: 4px 0 7px 35px;
}
.academia-sub-list li {
  display: block;
  position: relative;
  line-height: 2;
}