/*! sanitize.css | CC0 Public Domain | github.com/jonathantneal/sanitize.css */

$root-box-sizing: border-box !default;
$root-cursor: default !default;
$root-font-family: sans-serif !default;
$root-font-size: 16px !default;
$root-line-height: 1.5 !default;
$root-text-rendering: optimizeLegibility !default;

$html-background-color: #FFFFFF !default;
$anchor-text-decoration: none !default;
$form-element-background-color: transparent !default;
$form-element-min-height: if(unitless($root-line-height), #{$root-line-height}em, if(unit($root-line-height) != '%', $root-line-height, null)) !default;
$media-element-vertical-align: middle !default;
$monospace-font-family: monospace !default;
$nav-list-style: none !default;
$selection-background-color: #B3D4FC !default;
$selection-text-shadow: none !default;
$small-font-size: 75% !default;
$table-border-collapse: collapse !default;
$table-border-spacing: 0 !default;
$textarea-resize: vertical !default;

/*
 * Normalization
 */

:root {
  -ms-overflow-style: -ms-autohiding-scrollbar; // IE11+
  overflow-y: scroll; // All browsers without overlaying scrollbars
  text-size-adjust: 100%; // iOS 8+
}

audio:not([controls]) {
  display: none; // Chrome 40+, iOS 8+, Safari 8+
}

details {
  display: block; // Firefox 36+, Internet Explorer 11+, Windows Phone 8.1+
}

input {
  &[type="number"] {
    width: auto; // Firefox 36+
  }

  &[type="search"] {
    -webkit-appearance: textfield; // Safari 8+

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none; // Safari 8+
    }
  }
}

main {
  display: block; // Android 4.3-, Internet Explorer 11+, Windows Phone 8.1+
}

summary {
  display: block; // Firefox 36+, Internet Explorer 11+, Windows Phone 8.1+
}

pre {
  overflow: auto; // Internet Explorer 11+
}

progress {
  display: inline-block; // Internet Explorer 11+, Windows Phone 8.1+
}

small {
  font-size: $small-font-size; // All browsers
}

template {
  display: none; // Android 4.3-, Internet Explorer 11+, iOS 7-, Safari 7-, Windows Phone 8.1+
}

textarea {
  overflow: auto; // Internet Explorer 11+
}

[hidden] {
  display: none; // Internet Explorer 10-
}

[unselectable] {
  user-select: none; // Android 4.4+, Chrome 40+, Firefox 36+, iOS 8+, Safari 8+
}

/*
 * Universal inheritance
 */

*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

/*
 * Opinionated defaults
 */

// specify solid border style of all elements

*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

// remove margin and padding of all elements

* {
  margin: 0;
  padding: 0;
}

// specify the root styles of the document

:root {
  box-sizing: $root-box-sizing;
  cursor: $root-cursor;
  font: #{$root-font-size} / #{$root-line-height} $root-font-family;
  text-rendering: $root-text-rendering;
}

// specify the html background color

html {
  background-color: $html-background-color;
}

// specify the text decoration of anchors

a {
  text-decoration: $anchor-text-decoration;
}

// specify media element style

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: $media-element-vertical-align;
}

// specify the background color of form elements

button,
input,
select,
textarea {
  background-color: $form-element-background-color;
}

// specify inheritance of form elements

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

// specify the minimum height of form elements

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: $form-element-min-height;
}

// specify the font family of code elements

code,
kbd,
pre,
samp {
  font-family: $monospace-font-family, monospace;
}

// specify the list style of nav lists

nav ol,
nav ul {
  list-style: $nav-list-style;
}

// style select like a standard input

select {
  -moz-appearance: none; // Firefox 36+
  -webkit-appearance: none; // Chrome 41+

  &::-ms-expand {
    display: none; // Internet Explorer 11+
  }

  &::-ms-value {
    color: currentColor; // Internet Explorer 11+
  }
}

// specify the border styles of tables

table {
  border-collapse: $table-border-collapse;
  border-spacing: $table-border-spacing;
}

// specify textarea resizability

textarea {
  resize: $textarea-resize;
}

// specify text selection background color and omit drop shadow

::selection {
  background-color: $selection-background-color; // required when declaring ::selection
  text-shadow: $selection-text-shadow;
}

// hide content from screens but not screenreaders

@media screen {
  [hidden~="screen"] {
    display: inherit;
  }

  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}
