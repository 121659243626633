body {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: $font-smoothing;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: $font-smoothing;
  text-rendering: $text-rendering;
  font-size: $base-font-size;
  font-family: $base-font-family;
  color: $base-font-color;
  background-color: $base-background-color;
  font-weight: 400;
}
