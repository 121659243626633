.contents-wrap {
  display: block;
  position: relative;
  width: 100%;
}

.content-inner {
  display: block;
  position: relative;
  max-width: 1215px;
  margin: 0 auto;
  width: 100%;
}
