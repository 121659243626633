.licence {
  .subpage-content-title {
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:before {
      background-color: $color-mecury;
      display: block;
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:first-child {
      margin-bottom: 63px;
    }
  }
}
