.statistics {
  background-color: $color-secondary;
  display: block;
  padding: 20px 35px;
  position: relative;
  width: 100%;

  @include breakpoint(850px) {
    padding: 40px 35px;
  }
}

.statistics-list {
	display: flex;
	max-width: $site-size;
	margin: 0 auto;
	position: relative;
  flex-wrap: wrap;
	width: 100%;
}

.statistics-item {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
  margin: 20px 0;
  width: calc(100% / 3);

  @include breakpoint(850px) {
    width: calc(100% / 6);
    margin: 0;
  }

	i {
		color: $color-primary;
		font-size: 48px;
		margin-bottom: 15px;
	}


	h3 {
		display: block;
		margin-bottom: 5px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -1px;
    position: relative;
    color: #999;
    text-transform: capitalize;
	}

	span {
		color: $color-white;
		display: block;
		font-size: 36px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -1px;
	}
}
