.membership-list {
  color: $color-secondary;
  display: flex;
  align-items: baseline;
  position: relative;
  flex-wrap: wrap;;
  left: -15px;
  width: calc(100% + 30px);
}

.membership-item {
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  align-self: stretch;
  padding: 0 15px;
  width: 100%;

  @include breakpoint($breakpoint-mobile) {
    width: calc(100% / 2);
  }

  @include breakpoint($breakpoint-tablet) {
      width: calc(100% / 3);
  }

  p {
    display: block;
    position: relative;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.membership-type {
  background: $color-secondary;
  display: block;
  position: relative;
  text-align: center;
  padding: 20px;

  span {
    color: $color-white;
    display: block;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 0;
    font-weight: bold;
    text-align: center;
    position: relative;
  }
}

.membership-pricing {
  display: block;
  padding: 30px 0;
  position: relative;
  width: 100%;

  &:before {
    background: #e6e6e6;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    width: 75%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.membership-pricing-price {
  display: block;
  position: relative;
  text-align: center;

  span {
    display: inline-block;
    position: relative;
    font-size: 48px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -4px;
    text-align: center;
  }
}

.membership-pricing-amount {
  font-size: 48px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -4px;
}

.membership-pricing-note {
  font-size: 16px;
  opacity: 0.5;
}

.membership-content {
  display: block;
  padding: 30px;
  position: relative;
  width: 100%;

  &:before {
    background: #e6e6e6;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    width: 75%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.membership-content-list {
  display: block;
  list-style-type: none;
  position: relative;
  width: 100%;

  li {
    display: block;
    font-size: 16px;
    text-align: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.membership-btn {
  display: block;
  padding: 30px;
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}


.paid-membership-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.paid-membership-item {
  display: block;
  padding: 0 15px;
  position: relative;
  width: 100%;

  @include breakpoint($breakpoint-mobile) {
    width: calc(100% / 2);
  }

  @include breakpoint($breakpoint-tablet) {

    width: calc(100% / 3);
  }

  &:nth-child(-n+3) {
    margin-bottom: 35px;
  }


  i {
    position: absolute;
    left: 0;
    top: 0;
  }

  h3 {
    color: #232323;
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4em;
    position: relative;
    text-transform: capitalize;
    margin-bottom: 12px;
  }

  p {
    color: $color-light-grey;
    font-size: 16px;
    line-height: 27px;
  }
}

.paid-membership-detail {
  padding-left: 60px;
  font-size: 16px;
  line-height: 1.6em;
    
    ul li {
        margin-left: 15px;
    }
}

