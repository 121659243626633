.footer {
	color: $color-secondary;
	// display: block;
	// padding: 0 30px;
	// position: relative;
	// max-width: 1260px;
	// margin: 0 auto;
	// width: 100%;

	.logo {
		margin: 0 auto;
		max-width: 170px;
	}
}

.footer-col-wrap {
	display: flex;
	flex-wrap: wrap;
	margin-top: 40px;
	position: relative;
	width: 100%;
}

.footer-col {
	display: block;
	position: relative;
	width: calc(100% / 4);

	&:not(:first-child) {
		padding-left: 3%;
	}

	h3 {
		display: block;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 15px;
		position: relative;
		text-transform: capitalize;
		color: #fff;
	}
}

.footer-nav {
	display: block;
	list-style: none;
	position: relative;
	width: 100%;
}


.footer-nav-item {
	display: block;
	position: relative;

	&:not(:last-child) {
		margin-bottom: 5px;
	}
}

.footer-nav-link {
	color: $color-primary;
	position: relative;
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 400;
}


.footer-contact {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 400;

	i {
		color: $color-primary;
		display: block;
		margin-right: 10px;
	}

	span {

	}
}

.footer-contact-intro {
	color: #999;
	display: block;
	padding-left: 0;
}

.copyright-container {
    margin: 40px 0px;
    border-top: 1px solid #e6e6e6;

}

.copyright {
    color: #ccc;
    /* text-align: center; */
    line-height: 60px;
    float: left;
}

.copyright-menu {
    float: right;
    line-height: 60px;
	letter-spacing: 0.3px;
}

.copyright-menu-item {
    display: inline-block;
    padding-left: 10px;
}

/* Hoover */
.copyright-nav-link {
	color: $color-primary;
	position: relative;
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 400;
}
.copyright-nav-link a:hover,
.footer-nav-link a:hover {
	color: #00a0e3 !important;
}

.footer-col a:hover,
.footer-col p a:hover,
.copyright-menu-item a:hover {
	color: #00a0e3 !important;
}

.footer-col a:visited,
.footer-col p a:link,
.footer-col p a:visited {
	color: #39c8df;
}