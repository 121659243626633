$bar-width: 100%;
$bar-height: 4px;
$bar-spacing: 9px;

.hamburger-wrap {
  display: block;
  position: relative;
  width: 35px;
  height: 24px;
  margin-left: 10px;
  z-index: 100;

  @include breakpoint($breakpoint-tablet) {
    display: none;
  }
}

.hamburger {
  border-radius: 10px;
  display: block;
  position: relative;
	transform: translateY($bar-spacing);
	background: $color-secondary;
	transition: all 0ms 300ms;
  width: $bar-width;
  height: $bar-height;
  pointer-events: none;

  &:before,
  &:after {
    border-radius: 10px;
    content: "";
  	position: absolute;
  	left: 0;
  	background: $color-secondary;
    width: $bar-width;
  	height: $bar-height;
  }

  &:before {
    bottom: $bar-spacing;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:after {
	  top: $bar-spacing;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .is-open & {
    background: transparent;

    &:before {
      background: $color-white;
      bottom: 0;
    	transform: rotate(-45deg);
    	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:after {
      background: $color-white;
      top: 0;
    	transform: rotate(45deg);
    	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
  }
}
