.subpage {
  display: block;
  padding: 0 20px 40px;
  position: relative;
  width: 100%;

  > p {
    color: $color-sub-text;
    display: block;
    margin-bottom: 12px;
    line-height: 2;
    letter-spacing: 0.002em;
  }
}

.subpage-title {
  color: $color-secondary;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  padding: 30px 20px;
  left: -20px;
  width: calc(100% + 40px);
  margin-bottom: 40px;
  position: relative; 

  h1 {
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -1px;
    font-weight: 400;
  }
}

.subpage-title-inner {
  display: block;
  position: relative;
  max-width: $site-size;
  margin: 0 auto;
  width: 100%;
}

.subpage-inner {
  display: flex;
  position: relative;
  max-width: $site-size;
  margin: 0 auto;
  width: 100%;
}

.subpage-content-title {
  color: $color-secondary;
  display: block;
  text-transform: none;
  line-height: 32px;
  letter-spacing: -1px;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  margin-bottom: 10px;
  position: relative;

  strong { font-weight: 500; }

  .-number {
    display: inline-block;
    margin-right: 25px;
  }
}

.subpage-box {
  display: block;
  position: relative;
  margin-bottom: 30px;

  > p {
    color: $color-sub-text;
    display: block;
    margin-bottom: 12px;
    line-height: 2;
    letter-spacing: 0.002em;
  }
}

.subpage-content {
  display: block;
  width: 72%;
  position: relative;
}

.subpage-text {
  color: $color-sub-text;
  display: block;
  margin-bottom: 12px;
  line-height: 2;
  letter-spacing: 0.002em;
}

.subpage-sidebar {
  display: block;
  width: 25%;
  position: relative;
  margin-left: 3%;
}




.default-link {
  color: $color-primary;
  transition: all .2s ease-in-out;

  &:hover {
		@include breakpoint($breakpoint-tablet) {
		  color: $color-cerulean;
		}
	}
}

.ordered-list {
  color: $color-sub-text;
  display: block;
  position: relative;
  list-style-type: decimal;
  width: 100%;
  list-style-position: inside;
  padding-left: 10px;
  margin-bottom: 12px;

  li {
    line-height: 2;
  }
}
