.section-con {
  display: block;
  padding: 80px 35px;
  position: relative;
  width: 100%;
}

.section-inner {
  display: block;
  position: relative;
  max-width: 1215px;
  margin: 0 auto;
  width: 100%;
}

.section-title {
  color: $color-secondary;
  display: block;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  font-weight: 700;
  position: relative;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.section-title-sub {
  display: block;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-weight: 400;
  position: relative;
  margin-bottom: 40px;
}
