.scroll {
  &::-webkit-scrollbar {
    @include wh(3px, 3px);
    padding: 0 2px;
    position: relative;
    z-index: 10;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-primary;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-secondary;
  }
}
